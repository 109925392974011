<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item label="状态">
        <el-select v-model="query.status" placeholder="选择状态" clearable>
          <el-option label="正常" :value="1"></el-option>
          <el-option label="冻结" :value="2"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="工会名称">
         <el-input v-model="query.name"></el-input>
      </el-form-item>
      <el-form-item label="城市">
          <el-cascader
            clearable
            v-model="cityList"
            :options="cityoptions"
            :props="cityProps"
            :placeholder="cityList[1]"
          ></el-cascader>
        </el-form-item>
         <el-form-item label="教学类目">
          <el-select
            v-model="query.category"
            clearable
            name="categories_child"
            remote
            style="width: 150px"
          >
            <el-option
              v-for="item in listPageChild"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
      </el-form-item>
    </el-form>

    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #status="{ row }">
        <el-tag size="small" type="info" v-if="row.status === 0">待审核</el-tag>
        <el-tag size="small" type="success" v-if="row.status === 1"
          >正常</el-tag
        >
        <el-tag size="small" type="danger" v-if="row.status === 2">冻结</el-tag>
      </template>
      <template #handle="{ row }">
         <el-button size="small" @click="openUpdate(row)" type="success"
          >编辑</el-button
        >
        <el-button size="small" @click="toDetails(row)" type="primary"
          >详情</el-button
        >
      </template>
    </auto-table>

    <addOrEditVue ref="edit" @success="getList(1)"></addOrEditVue>

  </div>
</template>

<script>
import coachSelect from "@/components/select/coachSelect.vue";
import addOrEditVue from './components/addOrEdit.vue';
export default {
  components: {
    coachSelect,
    addOrEditVue
  },
  data() {
    return {
      DataList: [],
      coach_id: "",
      query: {
        status: 1,
        name: null,
        category:null
      },
      Option: [
        { name: "名称", value: "name" },
        { name: "电话", value: "mobile" },
        { name: "城市", value: "city" },
         { name: "类目", value: "category"},
          { name: "工会人数", value: "number" },
        { name: "状态", value: "status", type: "custom" },
        { name: "创建时间", value: "create_time" },
        { name: "操作", value: "handle", type: "custom", width: "300px" },
      ],
      Total: 0,
      curAuth: {},
      show: false,
      adminInfo: JSON.parse(localStorage.getItem("managerInfo")),
      listPageChild:[],
      cityoptions: JSON.parse(localStorage.getItem("addrList")),
      cityProps: {
        value: "district",
        label: "district",
        children: "childrens",
      },
      cityList: [],
    };
  },

  mounted() {
    this.getList(1);
    this.GetListPageChild()
  },

  methods: {
     GetListPageChild() {
      let url = this.$axios({
        url: "/user/mechanismCategory/queryListPageChild",
        params: {
          type: 2,
          status: 2,
          source: "课程",
        },
      }).then((res) => {
        this.listPageChild = res.data.data;
      });
    },
    openUpdate(row){
        this.$refs.edit.open(row)
    },
    getLabel(list, v) {
      for (let index = 0; index < this[list].length; index++) {
        if (this[list][index].value === v) {
          return this[list][index].label;
        }
      }
    },
    toDetails(row) {
      this.$router.push('unionDetails?id='+row.id);
    },
    
    //列表
    getList(v) {
      let page = v || 1;
      let params = {
        currentPage: page,
        pageSize: 10,
        ...this.query
      };
      if(this.cityList && this.cityList.length > 0){
            params.district = this.cityList[2]
      }
     
      this.$axios({
        url: "/user/tradeUnion/queryManagerListPage",
        params,
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>