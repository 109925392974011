<template>
  <!-- 新增，修改 -->
  <div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <el-form :model="form" label-width="120px" ref="form">
        <el-form-item label="工会名称" required>
          <avatar-uploader
            style="border-radius: 50%"
            @getImgUrl="(v) => (form.logo = v)"
            :url="form.logo"
          ></avatar-uploader>
        </el-form-item>
        <el-form-item label="工会名称" required>
          <el-input v-model="form.name" placeholder="合作商名称"></el-input>
        </el-form-item>
        <el-form-item label="联系人" required>
          <el-input v-model="form.mobile" placeholder="联系人名称"></el-input>
        </el-form-item>
        <el-form-item label="公会人数" required>
          <el-input
            v-model="form.max_number"
            placeholder="联系人名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="公会类目" required>
          <el-select @change="cateChange" v-model="category" multiple>
            <el-option
              v-for="item in listPageChild"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="公会位置" required prop="city">
          <el-cascader
            v-model="cityList"
            :options="cityoptions"
            :props="cityProps"
            @change="cityChange"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="服务区域" required>
          <el-select @change="districtChange" v-model="district" multiple>
            <el-option
              v-for="item in districtList"
              :key="item.id"
              :label="item.district"
              :value="item.district"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="介绍">
          <el-input
            type="textarea"
            v-model="form.introduce"
            placeholder="工会介绍"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          v-if="form.id"
          type="primary"
          @click="updateForm"
          :loading="loading"
          >更 新</el-button
        >
        <el-button v-else type="primary" :loading="loading" @click="addForm"
          >新 增</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import citySelect from "@/components/select/citySelect.vue";
import { ObjectChangedValue } from "@/js/utils.js";
export default {
  components: {
    citySelect,
  },
  data() {
    return {
      clear: false,
      form: {},
      temp: {},
      cityList: [],
      dialogVisible: false,
      loading: false,
      listPageChild: [],
      category: [],
      district:[],
      districtList:[],

      cityoptions: JSON.parse(localStorage.getItem("addrList")),
      cityProps: {
        checkStrictly: this.checkStrictly,
        value: "district",
        label: "district",
        children: "childrens",
      },

      cityInfos: [],
    };
  },

  mounted() {
    this.GetListPageChild();
  },

  methods: {
    cityChange(v){
      this.form.province = v[0]
      this.form.city = v[1]
      this.form.district = v[2]
      this.getDistricts(v[1])
      this.district = []

    },
    districtChange(v){
         this.form.service_district = v.join(",")
    },
    getDistricts(name){
         let cityoptions = JSON.parse(localStorage.getItem("addrList"));

          cityoptions.forEach((item) => {
        if (item.childrens) {
          item.childrens.forEach((city) => {
            if (city.district == name) {
               city.childrens.unshift({district:'全城'})
              this.districtList = city.childrens
            }
          });
        }
      });
    },

    cateChange(v) {
      this.form.category = v.join(",");
    },
    GetListPageChild() {
      let url = this.$axios({
        url: "/user/mechanismCategory/queryListPageChild",
        params: {
          type: 2,
          status: 2,
          source: "课程",
        },
      }).then((res) => {
        this.listPageChild = res.data.data;
      });
    },
    addForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$axios({
            url: "/user/tradeUnion/insert",
            method: "post",
            data: this.form,
          })
            .then((res) => {
              this.$notify({
                message: "新增成功",
                duration: 2000,
                type: "success",
              });
              this.dialogVisible = false;
              this.loading = false;
              this.$emit("success", 1);
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },

    updateForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const data = ObjectChangedValue(this.temp, this.form);
          if (Object.keys(data).length == 0) {
            this.$message("没有修改数据，无需更新");
            return;
          }
          this.loading = true;
          this.$axios({
            url: "/user/tradeUnion/update",
            method: "post",
            data: {
              id: this.form.id,
              ...data,
            },
          })
            .then((res) => {
              this.$notify({
                message: "更新成功",
                duration: 2000,
                type: "success",
              });
              this.dialogVisible = false;
              this.loading = false;
              this.$emit("success", 1);
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },

    open(info) {
      this.cityList = [];
       this.district = [];
        this.category = [];
      if (info && info.id) {
        this.temp = Object.assign({}, info);
        this.form = info;
        this.cityList.push(info.province);
        this.cityList.push(info.city);
        this.cityList.push(info.district);
        this.getDistricts(info.city)
        this.district = info.service_district.split(",");
        this.category = info.category.split(",");
      } else {
        this.form = {};
        this.temp = {};
      }
      this.dialogVisible = true;
    },
    changeCity(v) {
      this.$set(this.form, "province", v[0]);
      this.$set(this.form, "city", v[1]);
      this.$set(this.form, "district", v[2]);
      this.cityList = v;
    },
  },
};
</script>

<style lang="less" scoped>
</style>